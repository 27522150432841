// Chakra imports
import {
  Box,
  useColorModeValue,
  Text,
  Button,
  Textarea,
  Input,
  FormLabel,
  Select,
} from "@chakra-ui/react";
// Assets
import React, { useState, useEffect } from "react";
import "./style.css";
import api from "services/api.services/api.services";
import DragDropFiles from "components/dnd/DragDropFiles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function NewRevue() {
  // Chakra Color Mode
  const [is_last, set_is_last] = useState(false);
  const [editeur, setEditeur] = useState([]);
  const [editeurId, setEditeurId] = useState(Number);
  const [file, setFile] = useState();
  const [errors, setErrors] = useState({});

  const btnColor = useColorModeValue("#3311db", "#7551ff");

  const user = JSON.parse(localStorage.getItem("user"));

  const config = { headers: { Authorization: `Bearer ${user.token_user}` } };

  const change_is_last = () => {
    set_is_last(!is_last);
  };

  console.log(editeur);

  const blueDivBackgroundStyle = {
    height: "45vh",
    backgroundImage:
      "linear-gradient(to bottom, var(--chakra-colors-brand-400), var(--chakra-colors-brand-600))",
    position: "absolute",
    width: "100%",
    borderRadius: "20px",
  };

  const tabButtonListStyle = {
    WebkitBoxPack: "start",
    justifyContent: "flex-start",
    flexDirection: "row",
    display: "flex",
    WebkitBoxAlign: "center",
    alignItems: "center",
    placeSelf: "center",
  };

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const fetchEditeur = async () => {
    try {
      const response = await api.get("editor/all", config);
      const data = response.data;
  
      // Filtrer pour obtenir seulement 'editeurId' et 'name'
      const editeursFiltres = data.map((editeur) => ({
        editeurId: editeur.editeurId,
        name: editeur.name
      }));
  
      setEditeur(editeursFiltres);  // Stocker seulement les champs filtrés
    } catch (error) {
      console.error("Erreur lors de la récupération des éditeurs :", error);
      setEditeur([]);  // En cas d'erreur, on met un tableau vide
    }
  };
  

  useEffect(() => {
    fetchEditeur();
  }, [editeurId]);

  function handleSubmit(event) {
    event.preventDefault();

    const twoToneButton = document.querySelector("#btn_post");
    if (twoToneButton) {
      twoToneButton.innerHTML = "Submiting";
      twoToneButton.classList.add("spinning");
    } else {
      console.log("not exist");
    }
    const form = event.target;

    const elements = form.elements;

    const titre = elements.titre.value;
    const contenu = elements.contenu.value;

    //Gestion des erreurs
    const validationErrors = {};
    if (!titre.trim()) {
      validationErrors.titre = "Veuillez entrer un titre";
    }
    if (!editeurId) {
      validationErrors.editeur = "Veuillez selectionner un editeur";
    }
    if (!file) {
      validationErrors.file = "Veuillez choisir une image";
    }
    if (!contenu.trim()) {
      validationErrors.contenu = "Veuillez entrer du contenu";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      twoToneButton.classList.remove("spinning");
      return;
    }

    //soumission du formulaire
    const url = "revues/upload/cover";
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    const conf = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };
    try {
      api.post(url, formData, conf, config).then((responsecover) => {
        // console.log(responsecover.data.filenaame);
        api
          .post(
            "revues/create",
            {
              title: titre,
              text: contenu,
              editeurId: parseInt(editeurId),
              cover: responsecover.data.filenaame,
            },
            config
          )
          .then((response) => {
            setErrors({});
            setFile("");
            setEditeur([]);
            setEditeurId();
            form.reset();
            toast.success("Successful Submit!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: 0,
              theme: "colored",
            });
            twoToneButton.classList.remove("spinning");
            twoToneButton.innerHTML = "Submit";
          });
      });
    } catch (error) {
      twoToneButton.classList.remove("spinning");
      twoToneButton.innerHTML = "Submit";
      if (error.request) {
        toast.error("Failed to connect, Verified your Internet connexion!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "colored",
        });
      } else if (error.response) {
        toast.error("Please enter the correct value of fields!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "colored",
        });
      } else console.error("Erreur lors de l Ajout de revue", error);
    }
  }
  let sidebarBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <>
      <Box>
        <div className="contentTabComponent">
          <div style={blueDivBackgroundStyle}></div>
          <Box
            style={{
              marginTop: "165px",
              zIndex: "0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form className="contentForms" onSubmit={handleSubmit}>
              <Box className="formTab">
                {/* {!is_last && ( */}
                <Box className="formTabContainer">
                  <Box
                    className="css-ulukh4"
                    bg={sidebarBg}
                    borderRadius="30px"
                  >
                    <Text className="chakra-text css-8rszb0" color={textColor}>
                      Ajouter une nouvelle revue
                    </Text>
                    <div className="css-1ofqig9">
                      <div className="css-1rrbyyl">
                        <div className="chakra-stack css-vuscuc">
                          <div class="css-1doh6tj">
                            <FormLabel
                              for="titre"
                              class="chakra-form__label css-186ooiv"
                              color={textColor}
                            >
                              Titre<p class="chakra-text css-72mia8"></p>
                            </FormLabel>
                            <Input
                              placeholder="eg. Elegant Chair"
                              name="titre"
                              maxh="44px"
                              id="titre"
                              class="chakra-input css-1t1l5ix"
                              // value={titre}
                              // onChange={(e) => {
                              //   setTitre(e.target.value);
                              // }}
                            />
                            {errors.titre && (
                              <Text
                                color={textColor}
                                fontWeight="500"
                                fontSize="14px"
                                padding={"5px"}
                              >
                                {errors.titre}
                              </Text>
                            )}
                          </div>
                          <div className="css-1doh6tj">
                            <FormLabel
                              for="editeur"
                              className="chakra-form__label css-186ooiv"
                              color={textColor}
                            >
                              Editeur
                              <p className="chakra-text css-72mia8"></p>
                            </FormLabel>
                            <Select
                              bg={sidebarBg}
                              placeholder="Selectionner un Editeur"
                              name="editeur"
                              id="editeur"
                              value={editeurId}
                              color={textColor}
                              onChange={(e) => setEditeurId(e.target.value)}
                            >
                              {editeur.map((option) => (
                                <option
                                  style={{ color: textColor}}
                                  key={option.editeurId}
                                  value={option.editeurId}
                                >
                                  {option.name}
                                </option>
                              ))}
                            </Select>
                            {errors.editeur && (
                              <Text
                                color={textColor}
                                fontWeight="500"
                                fontSize="14px"
                                padding={"5px"}
                              >
                                {errors.editeur}
                              </Text>
                            )}
                          </div>
                          <div className="css-1doh6tj">
                            <FormLabel
                              for="image"
                              className="chakra-form__label css-186ooiv"
                              color={textColor}
                            >
                              Selectionner une image
                              <p className="chakra-text css-72mia8"></p>
                            </FormLabel>
                            <Input
                              placeholder="eg. Elegant Chair"
                              name="image"
                              maxh="44px"
                              id="image"
                              type="file"
                              onChange={handleChange}
                              color={textColor}
                              className="chakra-input css-1t1l5ix"
                            ></Input>
                          </div>
                          {errors.file && (
                            <Text
                              color={textColor}
                              fontWeight="500"
                              fontSize="14px"
                              padding={"5px"}
                            >
                              {errors.file}
                            </Text>
                          )}
                        </div>
                        <div className="chakra-stack css-vuscuc">
                          {" "}
                          <div className="css-1doh6tj">
                            <FormLabel
                              for="Description"
                              className="chakra-form__label css-186ooiv"
                              color={textColor}
                            >
                              Contenu
                              <p className="chakra-text css-1mgk6sz"></p>
                            </FormLabel>
                            <Textarea
                              placeholder="Short description about the revues"
                              maxh="230px"
                              style={{ height: "230px" }}
                              id="contenu"
                              className="chakra-textarea css-xtktxr"
                              color={textColor}
                              // value={contenu}
                              // onChange={(e) => setContenu(e.target.value)}
                            ></Textarea>
                            {errors.contenu && (
                              <Text
                                color={textColor}
                                fontWeight="500"
                                fontSize="14px"
                                padding={"5px"}
                              >
                                {errors.contenu}
                              </Text>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="css-16s7o2u">
                        <Button
                          bg={btnColor}
                          _hover={{ bg: btnColor }}
                          _active={{ bg: btnColor }}
                          color="white"
                          id="btn_post"
                          type="submit"
                          className="chakra-button css-184xkan"
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Box>
                </Box>
                {/* )} */}
                {is_last && (
                  <div className="formTabContainer">
                    <div className="css-ulukh4">
                      <p className="chakra-text css-8rszb0">Media</p>
                      <div
                        role="button"
                        tabindex="0"
                        className="dropzone css-cejpi7"
                      >
                        <DragDropFiles />
                      </div>
                      <div className="css-16s7o2u">
                        <button
                          type="button"
                          className="chakra-button css-1id0lme"
                          onClick={change_is_last}
                        >
                          Prev
                        </button>
                        <button
                          type="button"
                          className="chakra-button css-dtf1yy"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Box>
            </form>
          </Box>
        </div>
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
