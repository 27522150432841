import {
  Box,
  SimpleGrid,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Flex,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  useDisclosure,
  FormControl,
  FormLabel,
  Button,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import React, { useState, useEffect, useRef } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import api from "services/api.services/api.services";
import { AuthData } from "services/auth.services/AuthWrapper";
import { columnsDataCheck } from "./variables/columnsData";
import tableDataCheck from "./variables/tableDataCheck.json";
import CheckTable_skeleton from "./components/CheckTable_skeleton";
import { MdDelete, MdEditDocument } from "react-icons/md";

const UserDataTable = (props) => {
  const { variant, background, children, placeholder, borderRadius, ...rest } =
    props;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [records, setRecords] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { user } = AuthData();
  const config = { headers: { Authorization: `Bearer ${user.token_user}` } };
  // Chakra Color Mode
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const textColor = useColorModeValue("navy.700", "white");
  const menuBg = useColorModeValue("white", "navy.800");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef();
  const finalRef = useRef();
  // createTheme creates a new theme named solarized that overrides the build in dark theme
  createTheme("solarized", {
    text: {
      primary: textColor,
      secondary: textColor,
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    background: {
      default: menuBg,
    },
    context: {
      background: "#e3f2fd",
      text: textColor,
    },
    divider: {
      default: textColor,
    },
    button: {
      default: textColor,
      focus: "rgba(0,0,0,.12)",
      hover: "rgba(0,0,0,.12)",
      disabled: "gray",
    },
    selected: {
      default: textColor,
      text: "rgba(0, 0, 0, 0.87)",
    },
    highlightOnHover: {
      default: "#EEEEEE",
      text: "rgba(0, 0, 0, 0.87)",
    },
    striped: {
      default: "#FAFAFA",
      text: textColor,
    },
  });
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
        fontWeight: 700,
        fontSize: "0.875rem",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "12px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );
  const columns = [
    {
      name: "USERNAME",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "EMAIL",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "COUNTRY",
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: "PHONE",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (row) => row.statusUser,
      sortable: true,
    },
    {
      name: "CREATEAT",
      selector: (row) => row.createdAt,
      sortable: true,
    },
    {
      name: "UPDATEAT",
      selector: (row) => row.updateAt,
      sortable: true,
    },
    {
      name: "ACTIONS",
      cell: (row) => (
        <Flex align="center">
          <Icon
            as={MdEditDocument}
            h="16px"
            w="16px"
            me="8px"
            cursor="pointer"
            color={textColorBrand}
            onClick={() => handleEditClick(row)}
          />

          <Icon
            as={MdDelete}
            h="16px"
            w="16px"
            me="8px"
            cursor="pointer"
            color="red.400"
            onClick={() => handleDeleteClick(row.revueId)}
          />
        </Flex>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "100px",
    },
  ];
  const fetchUserData = async () => {
    try {
      await api.get("/users/all", config).then((res) => {
        setItems(res.data.data);
        setRecords(res.data.data);
        setIsLoaded(true);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClick = (row) => {
    setIsEditing(true);
    setIsAdding(false);
    setIsDeleting(false);
    onOpen();
    setSelectedItem(row);
  };

  const handleDeleteClick = (id) => {
    setIsDeleting(true);
    setIsEditing(false);
    setIsAdding(false);
    onOpen();
    setSelectedItem(id);
  };

  const handleAddClick = () => {
    setIsDeleting(false);
    setIsEditing(false);
    setIsAdding(true);
    onOpen();
  };

  useEffect(() => {
    fetchUserData();
  }, [perPage]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log("Selected Rows: ", state.selectedRows);
  };

  const handleSearch = (e) => {
    const newData = items.filter((row) => {
      return (
        row.username.toLowerCase().includes(e.target.value.toLowerCase()) ||
        row.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        row.statusUser.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setRecords(newData);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <CheckTable_skeleton
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </SimpleGrid>
      </Box>
    );
  } else {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <Flex
            display="flex"
            justifyContent="space-between"
            textAlign="center"
            alignItems="center"
            flexDirection="row"
            bg={menuBg}
            flexWrap={{ base: "wrap", md: "nowrap" }}
            p="10px"
            borderRadius="30px"
            w={{ base: "100%", md: "550px" }}
          >
            <InputGroup zIndex={0} w={{ base: "100%", md: "390px" }} {...rest}>
              <InputLeftElement
                children={
                  <IconButton
                    bg="inherit"
                    borderRadius="inherit"
                    _hover="none"
                    _active={{
                      bg: "inherit",
                      transform: "none",
                      borderColor: "transparent",
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                    icon={
                      <SearchIcon color={searchIconColor} w="15px" h="15px" />
                    }
                  ></IconButton>
                }
              />
              <Input
                variant="search"
                fontSize="sm"
                bg={background ? background : inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: "gray.400", fontSize: "14px" }}
                borderRadius={borderRadius ? borderRadius : "30px"}
                placeholder={placeholder ? placeholder : "Search..."}
                onChange={handleSearch}
              />
            </InputGroup>
            <Button onClick={handleAddClick}>Create User</Button>
          </Flex>
          <DataTable
            title="Users"
            customStyles={customStyles}
            columns={columns}
            data={records}
            pagination
            selectableRows
            onSelectedRowsChange={handleChange}
            onChangeRowsPerPage={handlePerRowsChange}
            theme="solarized"
          />
        </SimpleGrid>
        {isEditing && (
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Edit an Revue</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
              <Text>No Route Exist for this action</Text>
              </ModalBody>

              <ModalFooter>
                <Button
                  id="btn_edt"
                  colorScheme="blue"
                  mr={3}
                >
                  update
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
        {isAdding && (
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create new revue</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
              <Text>No Route Exist for this action</Text>
              </ModalBody>

              <ModalFooter>
                <Button
                  id="btn_add"
                  colorScheme="blue"
                  mr={3}
                >
                  Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
        {isDeleting && (
          <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <OverlayTwo />
            <ModalContent>
              <ModalHeader>Confirm Deleting</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>Do you want confirm this action ?</Text>
              </ModalBody>
              <ModalFooter>
                <Button
                  id="btn_del"
                  colorScheme="red"
                  mr={3}
                >
                  Delete
                </Button>
                <Button onClick={onClose}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Box>
    );
  }
};

export default UserDataTable;
