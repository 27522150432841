
export const columnsDataCheck = [
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "COUNTRY",
    accessor: "country",
  },
  {
    Header: "PHONE",
    accessor: "phone",
  },
  {
    Header: "STATUS",
    accessor: "statusUser",
  },
  {
    Header: "CREATEDAT",
    accessor: "createdAt",
  },
  {
    Header: "UPDATEAT",
    accessor: "updateAt",
  },
];