// Chakra imports
import {
  Box,
  SimpleGrid,
  useColorModeValue,
  Input,
  Button,
  IconButton,
  InputGroup,
  InputLeftElement,
  useDisclosure,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  FormControl,
  FormLabel,
  Text,
} from "@chakra-ui/react";
// Assets
import React, { useEffect, useState, useRef } from "react";
import TableTopCreators_Skeleton from "./component/TableTopCreators_ Skeleton";
import tableDataTopCreators from "./variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "./variables/tableColumnsTopCreators";
import api from "services/api.services/api.services";
import { AuthData } from "services/auth.services/AuthWrapper";
import DataTable, { createTheme } from "react-data-table-component";
import { SearchIcon } from "@chakra-ui/icons";
import { MdDelete, MdEditDocument } from "react-icons/md";

export default function EditorTables(props) {
  const { variant, background, children, placeholder, borderRadius, ...rest } =
    props;
  const [editorData, setEditorData] = useState([]);
  const [records, setRecords] = useState([]);
  const { user } = AuthData();
  const [name, setName] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const config = { headers: { Authorization: `Bearer ${user.token_user}` } };
  const [isloading, setIsloading] = useState(true);
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const textColor = useColorModeValue("navy.700", "white");
  const menuBg = useColorModeValue("white", "navy.800");
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef();
  const finalRef = useRef();
  const [errors, setErrors] = useState({});
  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );


  // createTheme creates a new theme named solarized that overrides the build in dark theme
  createTheme("solarized", {
    text: {
      primary: textColor,
      secondary: textColor,
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    background: {
      default: menuBg,
    },
    context: {
      background: "#e3f2fd",
      text: textColor,
    },
    divider: {
      default: textColor,
    },
    button: {
      default: textColor,
      focus: "rgba(0,0,0,.12)",
      hover: "rgba(0,0,0,.12)",
      disabled: "gray",
    },
    selected: {
      default: textColor,
      text: "rgba(0, 0, 0, 0.87)",
    },
    highlightOnHover: {
      default: "#EEEEEE",
      text: "rgba(0, 0, 0, 0.87)",
    },
    striped: {
      default: "#FAFAFA",
      text: textColor,
    },
  });
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
        fontWeight: 700,
        fontSize: "0.875rem",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "12px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "EDITORID",
      selector: (row) => row.editeurId,
      sortable: true,
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "ACTIONS",
      cell: (row) => (
        <Flex align="center">
          <Icon
            as={MdEditDocument}
            h="16px"
            w="16px"
            me="8px"
            cursor="pointer"
            color={textColorBrand}
          />

          <Icon
            as={MdDelete}
            h="16px"
            w="16px"
            me="8px"
            cursor="pointer"
            color="red.400"
            onClick={() => handleDeleteClick(row.revueId)}
          />
        </Flex>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleDeleteClick = (id) => {
    setIsDeleting(true);
    setIsAdding(false);
    onOpen();
    setSelectedItem(id);
  };

  const handleAddClick = () => {
    setIsDeleting(false);
    setIsAdding(true);
    onOpen();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const twoToneButton = document.querySelector("#btn_edt");
    if (twoToneButton) {
      twoToneButton.innerHTML = "saving";
      twoToneButton.classList.add("spinning");
    } else {
      console.log("not exist");
    }

    // Handle Error
    const validationErrors = {};
    if (!name.trim()) {
      validationErrors.name = "This field is Required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      twoToneButton.classList.remove("spinning");
      twoToneButton.innerHTML = "Try Again";
      return;
    }

    try {
      api
        .post(
          "editor/create",
          {
            name: name,
            logo: "",
          },
          config
        )
        .then((res) => {
          setName("");
          twoToneButton.classList.remove("spinning");
          twoToneButton.innerHTML = "Save";
        });
    } catch (err) {
      console.log(err);
      twoToneButton.classList.remove("spinning");
      twoToneButton.innerHTML = "save failed";
    }
  };

  const fetchEditorData = async () => {
    try {
      api.get("editor/all", config).then((res) => {
        setEditorData(res.data.data);
        setRecords(res.data.data);
        setIsloading(false);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = (e) => {
    const newData = editorData.filter((row) => {
      return (
        row.name.toLowerCase().includes(e.target.value.toLowerCase()) 
      );
    });
    setRecords(newData);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  useEffect(() => {
    fetchEditorData();
  }, [perPage]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {isloading ? (
          <TableTopCreators_Skeleton
            tableData={tableDataTopCreators}
            columnsData={tableColumnsTopCreators}
          />
        ) : (
          <>
            <Flex
              display="flex"
              justifyContent="space-between"
              textAlign="center"
              alignItems="center"
              flexDirection="row"
              bg={menuBg}
              flexWrap={{ base: "wrap", md: "nowrap" }}
              p="10px"
              borderRadius="30px"
              w={{ base: "100%", md: "550px" }}
            >
              <InputGroup
                zIndex={0}
                w={{ base: "100%", md: "390px" }}
                {...rest}
              >
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon color={searchIconColor} w="15px" h="15px" />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="sm"
                  bg={background ? background : inputBg}
                  color={inputText}
                  fontWeight="500"
                  _placeholder={{ color: "gray.400", fontSize: "14px" }}
                  borderRadius={borderRadius ? borderRadius : "30px"}
                  placeholder={placeholder ? placeholder : "Search..."}
                  onChange={handleSearch}
                />
              </InputGroup>
              <Button onClick={handleAddClick}>Add Editor</Button>
            </Flex>
            <DataTable
              title="Editors"
              columns={columns}
              data={records}
              customStyles={customStyles}
              pagination
              selectableRows
              theme="solarized"
              onChangeRowsPerPage={handlePerRowsChange}
            />
          </>
        )}
      </SimpleGrid>
      {isAdding && (
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create an Editor</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  ref={initialRef}
                  placeholder="Enter name"
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              {errors.name && (
                <Text
                  color="red.400"
                  fontWeight="500"
                  fontSize="14px"
                  padding={"5px"}
                >
                  {errors.name}
                </Text>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                id="btn_edt"
                onClick={handleSubmit}
                colorScheme="blue"
                mr={3}
                w="100px"
              >
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {isDeleting && (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          <OverlayTwo />
          <ModalContent>
            <ModalHeader>Confirm Deleting</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Do you want confirm this action ?</Text>
            </ModalBody>
            <ModalFooter>
              <Button
                id="btn_del"
                colorScheme="red"
                mr={3}
              >
                Delete
              </Button>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}
