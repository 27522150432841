import {
  Box,
  SimpleGrid,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Flex,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  useDisclosure,
  FormControl,
  FormLabel,
  Button,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import React, { useState, useEffect, useRef } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import api from "services/api.services/api.services";
import { AuthData } from "services/auth.services/AuthWrapper";
import { columnsDataCheck } from "./variables/columnsData";
import tableDataCheck from "./variables/tableDataCheck.json";
import CheckTable_skeleton from "./components/CheckTable_skeleton";
import { MdDelete, MdEditDocument } from "react-icons/md";

const RevueDataTable = (props) => {
  const { variant, background, children, placeholder, borderRadius, ...rest } =
    props;
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [records, setRecords] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editeur, setEditeur] = useState([]);
  const [editeurId, setEditeurId] = useState(Number);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [texte, setTexte] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState({});

  // const { user } = AuthData();
  const user = JSON.parse(localStorage.getItem("user"));
  const config = { headers: { Authorization: `Bearer ${user.token_user}` } };
  // Chakra Color Mode
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const textColorBrand = useColorModeValue("brand.500", "#868CFF");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const textColor = useColorModeValue("navy.700", "white");
  const menuBg = useColorModeValue("white", "navy.800");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef();
  const finalRef = useRef();
  // createTheme creates a new theme named solarized that overrides the build in dark theme
  createTheme("solarized", {
    text: {
      primary: textColor,
      secondary: textColor,
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    background: {
      default: menuBg,
    },
    context: {
      background: "#e3f2fd",
      text: textColor,
    },
    divider: {
      default: textColor,
    },
    button: {
      default: textColor,
      focus: "rgba(0,0,0,.12)",
      hover: "rgba(0,0,0,.12)",
      disabled: "gray",
    },
    selected: {
      default: textColor,
      text: "rgba(0, 0, 0, 0.87)",
    },
    highlightOnHover: {
      default: "#EEEEEE",
      text: "rgba(0, 0, 0, 0.87)",
    },
    striped: {
      default: "#FAFAFA",
      text: textColor,
    },
  });
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: "52px", // override the row height
        fontWeight: 700,
        fontSize: "0.875rem",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "12px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );
  const columns = [
    {
      name: "REVUEID",
      selector: (row) => row.revueId,
      sortable: true,
      width: "100px",
    },
    {
      name: "TITLE",
      selector: (row) => row.title,
      sortable: true,
      width: "200px",
    },
    {
      name: "TEXT",
      selector: (row) => row.text,
      sortable: true,
      width: "500px",
    },
    {
      name: "EDITOR",
      selector: (row) => row.editeur.name,
      sortable: true,
      width: "150px",
    },
    {
      name: "COVER",
      selector: (row) => row.cover,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "CREATEAT",
    //   selector: (row) => row.createdAt,
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "UPDATEAT",
    //   selector: (row) => row.updateAt,
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "ACTIONS",
      cell: (row) => (
        <Flex align="center">
          <Icon
            as={MdEditDocument}
            h="16px"
            w="16px"
            me="8px"
            cursor="pointer"
            color={textColorBrand}
            onClick={() => handleEditClick(row)}
          />

          <Icon
            as={MdDelete}
            h="16px"
            w="16px"
            me="8px"
            cursor="pointer"
            color="red.400"
            onClick={() => handleDeleteClick(row.revueId)}
          />
        </Flex>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "100px",
    },
  ];
  const fetchEditeur = async () => {
    //Recuperer la liste des editeurs
    try {
      api.get("editor/all", config).then((response) => {
       setEditeur(response.data.data);
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des editeurs :", error);
    }
  };

  const handleEditClick = (row) => {
    setIsEditing(true);
    setIsAdding(false);
    setIsDeleting(false);
    onOpen();
    setSelectedItem(row);
    setTitle(row.title);
    setTexte(row.text);
  };

  const handleDeleteClick = (id) => {
    setIsDeleting(true);
    setIsEditing(false);
    setIsAdding(false);
    onOpen();
    setSelectedItem(id);
    setTitle("");
    setTexte("");
    setFile(null);
  };

  const handleAddClick = () => {
    setIsDeleting(false);
    setIsEditing(false);
    setIsAdding(true);
    onOpen();
    setTitle("");
    setTexte("");
    setFile(null);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const twoToneButton = document.querySelector("#btn_edt");
    if (twoToneButton) {
      twoToneButton.innerHTML = "updating";
      twoToneButton.classList.add("spinning");
    } else {
      console.log("not exist");
    }
    // Handle Error
    const validationErrors = {};
    if (!title.trim()) {
      validationErrors.title = "Title field is Required";
    }
    if (!texte.trim()) {
      validationErrors.texte = "Text field is Required";
    }
    if (!editeurId) {
      validationErrors.editeurId = "Editor field is Required";
    }
    if (!file) {
      validationErrors.file = "Cover field is Required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      twoToneButton.classList.remove("spinning");
      twoToneButton.innerHTML = "Try Again";
      return;
    }
    const url = "revues/upload/cover";
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    const conf = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };

    try {
      api.post(url, formData, conf, config).then((responsecover) => {
        api
          .put(
            `/revues/update/${selectedItem.revueId}`,
            {
              title: title,
              text: texte,
              editeurId: parseInt(editeurId),
              cover: responsecover.data.filenaame,
            },
            config
          )
          .then((res) => {
            twoToneButton.classList.remove("spinning");
            twoToneButton.innerHTML = "Updated";
          });
        const updatedData = records.map((item) =>
          item.id === selectedItem.id ? selectedItem : item
        );
        setRecords(updatedData);
      });
    } catch (error) {
      console.log("Error updating data :", error);
      console.error("Error updating data :", error);
      twoToneButton.classList.remove("spinning");
      twoToneButton.innerHTML = "Failed";
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const twoToneButton = document.querySelector("#btn_del");
    if (twoToneButton) {
      twoToneButton.innerHTML = "Deleting";
      twoToneButton.classList.add("spinning");
    } else {
      console.log("not exist");
    }
    try {
      await api.put(`/revues/delete/${selectedItem.revueId}`, config);
      const updatedData = records.map(
        (item) => item.id !== selectedItem.revueId
      );
      setRecords(updatedData);
      twoToneButton.classList.remove("spinning");
      twoToneButton.innerHTML = "Deleted";
    } catch (error) {
      console.log("Error deleting data :", error);
      console.error("Error deleting data :", error);
      twoToneButton.classList.remove("spinning");
      twoToneButton.innerHTML = "Failed";
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    const twoToneButton = document.querySelector("#btn_add");
    if (twoToneButton) {
      twoToneButton.innerHTML = "Saving";
      twoToneButton.classList.add("spinning");
    } else {
      console.log("not exist");
    }
    // Handle Error
    const validationErrors = {};
    if (!title.trim() || !texte.trim() || !editeurId || !file) {
      validationErrors.title = "Title field is Required";
    }
    if (!texte.trim()) {
      validationErrors.texte = "Text field is Required";
    }
    if (!editeurId) {
      validationErrors.editeurId = "Editor field is Required";
    }
    if (!file) {
      validationErrors.file = "Cover field is Required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      twoToneButton.classList.remove("spinning");
      twoToneButton.innerHTML = "Try Again";
      return;
    }

    //soumission du formulaire
    const url = "revues/upload/cover";
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    const conf = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };
    try {
      api.post(url, formData, conf, config).then((responsecover) => {
        // console.log(responsecover.data.filenaame);
        api
          .post(
            "revues/create",
            {
              title: title,
              text: texte,
              editeurId: parseInt(editeurId),
              cover: responsecover.data.filenaame,
            },
            config
          )
          .then((response) => {
            setFile("");
            setEditeur([]);
            setEditeurId();
            setTexte("");
            setTitle("");
            twoToneButton.classList.remove("spinning");
            twoToneButton.innerHTML = "Save";
          });
      });
    } catch (error) {
      if (error.request) {
        console.error(" Request error ", error);
      } else if (error.response) {
        console.error(" Response error ", error);
      } else console.error("Erreur lors de l Ajout de revue", error);
      twoToneButton.classList.remove("spinning");
      twoToneButton.innerHTML = "Retry";
    }
  };

  useEffect(() => {
    fetchData(1);
    fetchEditeur();
  }, [perPage]);

  // ### Fetch Revues Data
  const fetchData = async (page) => {
    try {
      await api.get(`revues/all/${page}/true`, config).then((res) => {
        setItems(res.data.data);
        setRecords(res.data.data);
        setTotalRows(res.data.meta.total);
        setIsLoaded(true);
      });
    } catch (error) {
      setIsLoaded(true);
      console.log(error);
      setError(error);
    }
  };

  const handlePageChange = (page) => {
    fetchData(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log("Selected Rows: ", state.selectedRows);
  };

  const handleSearch = (e) => {
    const newData = items.filter((row) => {
      return (
        row.text.toLowerCase().includes(e.target.value.toLowerCase()) ||
        row.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        row.editeur.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setRecords(newData);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <CheckTable_skeleton
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </SimpleGrid>
      </Box>
    );
  } else {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <Flex
            display="flex"
            justifyContent="space-between"
            textAlign="center"
            alignItems="center"
            flexDirection="row"
            bg={menuBg}
            flexWrap={{ base: "wrap", md: "nowrap" }}
            p="10px"
            borderRadius="30px"
            w={{ base: "100%", md: "550px" }}
          >
            <InputGroup zIndex={0} w={{ base: "100%", md: "390px" }} {...rest}>
              <InputLeftElement
                children={
                  <IconButton
                    bg="inherit"
                    borderRadius="inherit"
                    _hover="none"
                    _active={{
                      bg: "inherit",
                      transform: "none",
                      borderColor: "transparent",
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                    icon={
                      <SearchIcon color={searchIconColor} w="15px" h="15px" />
                    }
                  ></IconButton>
                }
              />
              <Input
                variant="search"
                fontSize="sm"
                bg={background ? background : inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: "gray.400", fontSize: "14px" }}
                borderRadius={borderRadius ? borderRadius : "30px"}
                placeholder={placeholder ? placeholder : "Search..."}
                onChange={handleSearch}
              />
            </InputGroup>
            <Button onClick={handleAddClick}>Add Revue</Button>
          </Flex>
          <DataTable
            title="Revues"
            customStyles={customStyles}
            columns={columns}
            data={records}
            pagination
            paginationServer
            selectableRows
            onSelectedRowsChange={handleChange}
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            theme="solarized"
            highlightOnHover
            responsive
          />
        </SimpleGrid>
        {isEditing && (
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Edit an Revue</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl isRequired>
                  <FormLabel>Title</FormLabel>
                  <Input
                    ref={initialRef}
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>
                {errors.title && (
                  <Text
                    color="red.400"
                    fontWeight="500"
                    fontSize="14px"
                    padding={"5px"}
                  >
                    {errors.title}
                  </Text>
                )}
                <FormControl isRequired mt={4}>
                  <FormLabel>Text</FormLabel>
                  <Textarea
                    value={texte}
                    placeholder="Text"
                    h="290px"
                    onChange={(e) => setTexte(e.target.value)}
                  />
                </FormControl>
                {errors.texte && (
                  <Text
                    color="red.400"
                    fontWeight="500"
                    fontSize="14px"
                    padding={"5px"}
                  >
                    {errors.texte}
                  </Text>
                )}
                <FormControl isRequired mt={4}>
                  <FormLabel>Editor</FormLabel>
                  <Select
                    placeholder="Select editor"
                    onChange={(e) => setEditeurId(e.target.value)}
                  >
                    {editeur.map((option) => (
                      <option
                        style={{ color: textColor }}
                        key={option.editeurId}
                        value={option.editeurId}
                      >
                        {option.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                {errors.editeurId && (
                  <Text
                    color="red.400"
                    fontWeight="500"
                    fontSize="14px"
                    padding={"5px"}
                  >
                    {errors.editeurId}
                  </Text>
                )}
                <FormControl isRequired mt={4}>
                  <FormLabel>Cover</FormLabel>
                  <Input
                    type="file"
                    placeholder="cover"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </FormControl>
                {errors.file && (
                  <Text
                    color="red.400"
                    fontWeight="500"
                    fontSize="14px"
                    padding={"5px"}
                  >
                    {errors.file}
                  </Text>
                )}
              </ModalBody>

              <ModalFooter>
                <Button
                  onClick={handleEdit}
                  id="btn_edt"
                  colorScheme="blue"
                  w="200px"
                  mr={3}
                >
                  update
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
        {isAdding && (
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create new revue</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl isRequired>
                  <FormLabel>Title</FormLabel>
                  <Input
                    ref={initialRef}
                    placeholder="title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>
                {errors.title && (
                  <Text
                    color="red.400"
                    fontWeight="500"
                    fontSize="14px"
                    padding={"5px"}
                  >
                    {errors.title}
                  </Text>
                )}
                <FormControl isRequired mt={4}>
                  <FormLabel>Text</FormLabel>
                  <Textarea
                    value={texte}
                    placeholder="text"
                    h="290px"
                    onChange={(e) => setTexte(e.target.value)}
                  />
                </FormControl>
                {errors.texte && (
                  <Text
                    color="red.400"
                    fontWeight="500"
                    fontSize="14px"
                    padding={"5px"}
                  >
                    {errors.texte}
                  </Text>
                )}
                <FormControl isRequired mt={4}>
                  <FormLabel>Editor</FormLabel>
                  <Select
                    placeholder="Select editor"
                    onChange={(e) => setEditeurId(e.target.value)}
                  >
                    {editeur.map((option) => (
                      <option
                        style={{ color: textColor }}
                        key={option.editeurId}
                        value={option.editeurId}
                      >
                        {option.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                {errors.editeurId && (
                  <Text
                    color="red.400"
                    fontWeight="500"
                    fontSize="14px"
                    padding={"5px"}
                  >
                    {errors.editeurId}
                  </Text>
                )}
                <FormControl isRequired mt={4}>
                  <FormLabel>Cover</FormLabel>
                  <Input
                    type="file"
                    placeholder="cover"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </FormControl>
                {errors.file && (
                  <Text
                    color="red.400"
                    fontWeight="500"
                    fontSize="14px"
                    padding={"5px"}
                  >
                    {errors.file}
                  </Text>
                )}
              </ModalBody>

              <ModalFooter>
                <Button
                  onClick={handleAdd}
                  id="btn_add"
                  colorScheme="blue"
                  mr={3}
                >
                  Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
        {isDeleting && (
          <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <OverlayTwo />
            <ModalContent>
              <ModalHeader>Confirm Deleting</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>Do you want confirm this action ?</Text>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={handleDelete}
                  id="btn_del"
                  colorScheme="red"
                  mr={3}
                >
                  Delete
                </Button>
                <Button onClick={onClose}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Box>
    );
  }
};

export default RevueDataTable;
